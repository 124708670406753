<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <create-permission-group
      v-if="showGroupCreateForm"
      @closed="showGroupCreateForm = false"
    />
    <edit-permission-group
      v-if="showGroupEditForm"
      :groupname="permissionGroupName"
      :groupid="permissionGroupId"
      :existingpermissions="existingPermissions"
      @closed="showGroupEditForm = false"
    />
    <assigned-permissions-list
      v-if="showAssignedPermissionsList"
      :assignedpermissionslist="assignedPermissionsList"
      :groupname="permissionGroupName"
      :pageheader="pageHeader"
      @closed="showAssignedPermissionsList = false"
    />
    <h2 class="title-style">
      <v-icon class="icon-style">
        mdi-account-lock
      </v-icon> Permission Groups
    </h2>
    <v-card
      shaped
      color="#CFD8DC"
    >
      <br>
      <v-col
        class="text-right"
      >
        <v-btn
          color="#FF3700"
          @click="showGroupCreateForm = true"
        >
          Create Group
        </v-btn>
      </v-col>
      <v-card-title>
        <v-row>
          <v-col
            cols="12"
            lg="4"
          >
            <v-text-field
              v-model="search"
              label="Search"
              append-icon="mdi-magnify"
              outlined
              dense
            />
          </v-col>
        </v-row>
      </v-card-title>
      <v-row>
        <v-col>
          <v-data-table
            :headers="permissionGroupTable.headers"
            :items="permissionGroups"
            :search="search"
            class="elevation-1 clickable"
            mobile-breakpoint="100"
          >
            <template v-slot:item="{ item }">
              <tr
                class="clickable"
              >
                <td>{{ item.name }}</td>
                <td>
                  <v-btn
                    v-if="item.permissions.length !== 0"
                    text
                    class="button-style"
                    @click="viewAssignedPermissionsList(item.permissions, item.name)"
                  >
                    <v-icon left>
                      mdi-eye
                    </v-icon>
                    View
                  </v-btn>
                  <p v-else>
                    N/A
                  </p>
                </td>
                <td>
                  <v-btn
                    class="mt-n2"
                    elevation="1"
                    fab
                    color="#37474F"
                    x-small
                    icon
                    @click="editPermissionGroup(item.name, item.id, item.permissions)"
                  >
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <div class="py-3" />
      <centre-spinner
        :loading="loading"
      />
    </v-card>
  </v-container>
</template>
<script>
  import spinner from 'src/views/dashboard/component/SpinnerCentre';
  import CreatePermissionGroup from './CreatePermissionGroup.vue';
  import EditPermissionGroup from './EditPermissionGroup.vue';
  import AssignedPermissionsList from './AssignedPermissionsList.vue';

  export default {
    name: 'PermissionGroups',
    components: {
      'centre-spinner': spinner,
      'create-permission-group': CreatePermissionGroup,
      'edit-permission-group': EditPermissionGroup,
      'assigned-permissions-list': AssignedPermissionsList,
    },
    data () {
      return {
        loading: false,
        search: '',
        permissionGroupTable: {
          headers: [
            { text: 'Group Name', align: 'start', value: 'name' },
            { text: 'Permissions', value: 'Permissions' },
            { text: 'Edit Permissions', value: 'edit' },
          ],
        },
        showGroupCreateForm: false,
        showGroupEditForm: false,
        permissionGroupName: '',
        permissionGroupId: '',
        existingPermissions: [],
        showAssignedPermissionsList: false,
        assignedPermissionsList: [],
        pageHeader: '',
      };
    },
    computed: {
      permissionGroups () {
        return this.$store.getters['accountsettings/getPermissionGroups'];
      },
    },
    mounted () {
      this.getPermissionGroups();
    },
    methods: {
      async getPermissionGroups () {
        this.loading = true;
        await this.$store.dispatch('accountsettings/fetchPermissionGroups');
        this.loading = false;
      },
      editPermissionGroup (groupName, groupId, existingPermissions) {
        this.permissionGroupName = groupName;
        this.permissionGroupId = groupId;
        this.existingPermissions = existingPermissions;
        this.showGroupEditForm = true;
      },
      viewAssignedPermissionsList (assignedPermissionsList, permissionGroupName) {
        this.assignedPermissionsList = assignedPermissionsList;
        this.permissionGroupName = permissionGroupName;
        this.pageHeader = 'Assigned Permissions';
        this.showAssignedPermissionsList = true;
      },
    },
  };
</script>
<style scoped>
.clickable {
  cursor: pointer;
}
.v-data-table::v-deep th {
  font-size: 12px !important;
  color: #37474F !important;
  font-weight: bold !important;
}
.v-data-table::v-deep td {
  font-size: 13px !important;
  color: #37474F !important;
}
.theme--light.v-data-table tbody tr:nth-of-type(even) {
  background-color: #CFD8DC
}
.font-my-style {
  font-weight: bold;
}
.v-data-table {
  background-color: #ECEFF1;
}
.my-toolbar-style {
  color: #37474F;
  font-weight: bold;
}
.title-style {
  color: #37474F;
  margin-left: 20px;
}
.icon-style {
  color: #37474F;
  font-size: 45px;
}
.button-style {
  font-weight: bold;
  color: #37474F;
}
.v-data-table-header th {
  white-space: nowrap;
}
</style>
